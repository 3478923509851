
import './copilot.css';

export const CoPilot = ()=>{
    return(<>
        

        <div>
            <div className='copil'>

            </div>
            <p className='wrapppp' style={{
                color:'#222', 
            fontSize:'14px', 
            wordSpacing:'5px',
            letterSpacing:'1px',
            paddingTop:'13px',
            }}>
                Verification Complete
            {/* <b style={{color:'rgb(234, 62, 35)'}}>AI</b> */}
            </p>

        </div>
        
    </>)
};